import React from 'react';
import Container from 'container';
import Img from 'img';
import Icon from 'icon';
import Button from 'button';
import SimpleTitleSubtitle from 'shared/section-headlines/simple-title-subtitle';
import s from './testimonials-popup.module.scss';

export default function TestimonialsPopup({
  title,
  subtitle,
  images,
  button,
  popup,
  ...props
}) {
  return (
    <Container className={s.root}>
      <div className={s.wrapper}>
        <div className={s.close} onClick={() => popup.hide()}>
          <Icon icon="close" />
        </div>
        <SimpleTitleSubtitle
          className={s.title}
          titleClassName={s.titleClassName}
          {...{ title, subtitle }}
        />
        {images.map(({ image }) => (
          <div className={s.imgWrapper}>
            <Img className={s.img} src={image?.title} alt={image?.title} />
          </div>
        ))}
        {Object.keys(button).length > 0 && (
          <div className={s.buttonWrapper}>
            <Button
              secondary
              href={button.href}
              native={!!button.href.includes('http')}
            >
              {button.text}
            </Button>
          </div>
        )}
      </div>
    </Container>
  );
}
