import React from 'react';
import Modal from 'shared/modal';
import Icon from 'icon';
import s from './popup-generator.module.scss';

export default function PopupGenerator({ popups, popup, ...props }) {
  const popupData = popups[popup.selected];
  try {
    const Popup = require(`components/popup-templates/${
      popupData.acf_fc_layout
    }`).default;
    return (
      <>
        {popup.isShowing && (
          <Modal>
            <div className={s.popupWrapper}>
              <div className={s.overlay} onClick={() => popup.hide()} />
              <div style={{ zIndex: '99' }}>
                <Popup {...{ popup, ...props }} {...popupData} />
              </div>
            </div>
          </Modal>
        )}
      </>
    );
  } catch (e) {
    
    return null;
  }
}
