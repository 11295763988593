import React from 'react';
import Container from 'container';
import Img from 'img';
import Headline from 'headline';
import getTag from 'utils/get-tag';
import Button from 'button';
import { Link, navigate } from 'gatsby';
import Icon from 'icon';
import Card from 'shared/cards/card-g';
import s from './blog-categories.module.scss';

export default function BlogCategories({ categories, title, slug, ...props }) {
  const omitCategories = ['webinar', 'adds']; // Slugs
  const categoryData = {
    abundance: { image: 'lak-og', text: 'Lorem ipsum dolor sit amet' },
    adds: { image: 'lak-og', text: 'Lorem ipsum dolor sit amet' },
    energy: { image: 'lak-og', text: 'Lorem ipsum dolor sit amet' },
    'energy-healing': { image: 'lak-og', text: 'Lorem ipsum dolor sit amet' },
    growth: { image: 'lak-og', text: 'Lorem ipsum dolor sit amet' },
    manifesting: { image: 'lak-og', text: 'Lorem ipsum dolor sit amet' },
    'my-life': { image: 'lak-og', text: 'Lorem ipsum dolor sit amet' },
    'personal-growth': { image: 'lak-og', text: 'Lorem ipsum dolor sit amet' },
    relationships: { image: 'lak-og', text: 'Lorem ipsum dolor sit amet' },
    'sexual-energy': { image: 'lak-og', text: 'Lorem ipsum dolor sit amet' },
    webinar: { image: 'lak-og', text: 'Lorem ipsum dolor sit amet' }
  };
  const fufilledCategories = categories
    .map((el, i) => ({
      image: { title: categoryData[el.slug].image },
      title: el.name,
      text: categoryData[el.slug].text,
      slug: el.slug,
      index: i
    }))
    .filter(({ title }) => !omitCategories.includes(title));
  return (
    <Container className={s.root}>
      <div className={s.headlineWrapper}>
        <Headline subtitle primary html={title} className={s.headline} />
      </div>
      <div className={s.categories}>
        {fufilledCategories.map((el, i) => (
          <div className={s.cardWrapper} key={`${el.title}-${i}`}>
            <Card {...{ ...el, index: i }} className={s.card} />
          </div>
        ))}
      </div>
      {slug !== '/blog/all-posts' && (
        <div className={s.cta}>
          {slug !== undefined && (
            <span className={s.ctaText}>
              CAN'T DECIDE WHERE TO BEGIN? BROWSE EVERYTHING
            </span>
          )}
          <span
            className={s.ctaLink}
            onClick={() => navigate('blog/all-posts/')}
          >
            {slug !== undefined ? 'ALL CATEGORIES' : 'BROWSE ALL CATEGORIES'}
            <Icon icon="readmorearrow" className={s.arrow} />
          </span>
        </div>
      )}
    </Container>
  );
}
