import React from 'react';
import Img from 'img';
import Headline from 'headline';
import getTag from 'utils/get-tag';
import Button from 'button';
import { navigate, Link } from 'gatsby';
import { usePopup } from '@/hooks/usePopup';
import PopupGenerator from 'components/popup-generator';
import s from './card-d.module.scss';

export default function CardD({
  image,
  title,
  text,
  button,
  slug,
  index,
  hasPopup,
  className,
  ...props
}) {
  const TextTag = getTag(text);
  const { popup } = usePopup();
  return (
    <div className={`${s.root} ${className || ''}`}>
      <Link to={`/blog/categories/${slug}/`}>
        <div className={s.imageWrapper}>
          <Img className={s.img} src={image?.title} alt={image?.title} />
        </div>
        <div className={s.content}>
          <Headline h3 className={s.title} html={title} />
          <TextTag
            className={`${s.text} italic`}
            dangerouslySetInnerHTML={{ __html: text }}
          />
          {hasPopup ? (
            <Button
              className={s.button}
              tertiary
              onClick={() => {
                popup.show(index);
              }}
            >
              {button.text}
            </Button>
          ) : (
            button !== undefined &&
            button !== null &&
            Object.keys(button).length > 0 && (
              <Button
                className={s.button}
                tertiary
                href={button.href}
                scroll={button.scroll}
              >
                {button.text}
              </Button>
            )
          )}
          {hasPopup && <PopupGenerator {...{ popup, ...props }} />}
        </div>
      </Link>
    </div>
  );
}
